import { render, staticRenderFns } from "./ClientProductPage.vue?vue&type=template&id=56312926"
import script from "./ClientProductPage.vue?vue&type=script&lang=js"
export * from "./ClientProductPage.vue?vue&type=script&lang=js"
import style0 from "./ClientProductPage.vue?vue&type=style&index=0&id=56312926&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
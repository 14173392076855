<template>
  <div>
    <div class="card">
      <h3>
        <u>{{ name }}</u>
      </h3>
      <b-container>
        <div class="row">
          <div class="col-sm-4">
            <div class="box box1">
              <img :src="img" alt="" />
            </div>
          </div>
          <div class="col">
            <div class="col">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >SKU</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ean"
                  v-model="sku"
                  disabled
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Name</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ean"
                  v-model="name"
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Img Link</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="img"
                  v-model="img"
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Product Link</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="product_link"
                  v-model="product_link"
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Price Sale</span
                  >
                </div>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="price_sale"
                  v-model="price_sale"
                />
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary float-right" @click="editProduct()">Save</button>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ClientProductPage",
  data() {
    return {
      name: "",
      img: "",
      sku: "",
      product_link: "",
      price_sale: "",
    };
  },
  methods: {
    async getClientProduct() {
      console.log(this.$route.params.sku);
      try {
        await this.$store.dispatch("getClientProduct", {
          sku: this.$route.params.sku,
        });
        console.log(this.getProductClient);
        this.name = this.getProductClient[0].name;
        this.img = this.getProductClient[0].img;
        this.sku = this.getProductClient[0].sku_client;
        this.product_link = this.getProductClient[0].product_link;
        this.price_sale = this.getProductClient[0].price_sale;
      } catch (error) {
        console.log(error);
      }
    },
    async editProduct() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, edit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("editClientProduct", {
                sku: this.sku,
                name: this.name,
                img: this.img,
                product_link: this.product_link,
                price_sale: this.price_sale,
              });
              this.$swal.fire(
                "Edited!",
                "Your product has been edited.",
                "success"
              );
            } catch (error) {
              console.log(error);
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getProductClient"]),
  },
  created() {
    this.getClientProduct();
  },
};
</script>
<style>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
h3 {
  text-align: center;
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.col {
  margin: 10px;
}
img {
  max-width: 80%;
}
</style>